.websiteLink p {
  display: inline;
}

.websiteLink a {
  color: #f2fdff;
  word-wrap: break-word;
}

.times {
  position: absolute;
  background-color: transparent;
  color: #f2fdff;
  top: 10px;
  right: 5px;
  border: none;
}

address {
  margin: 1em 0;
}
