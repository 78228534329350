.App {
  text-align: center;
  font-family: "Montserrat", sans-serif;
}

main {
  height: calc(100vh - 56px);
  position: relative;
  top: 56px;
}

.error {
  padding: 10px 20%;
  background-color: #820933;
  color: white;
  border: 4px solid white;
  margin: 0;
  font-size: 1.1em;
}

@supports (-webkit-overflow-scrolling: touch) {
  /* CSS specific to iOS devices */
  main {
    height: calc(100vh - 100px);
  }
}
