.navBar {
  background-color: #820933;
  color: gainsboro;
  position: fixed;
  top: 0;
  padding: 10px;
  width: 100%;
  z-index: 1;
}

.appTitle {
  font-family: "Poiret One", cursive;
  text-align: left;
  margin: 0;
}

.appTitle a {
  color: gainsboro;
  text-decoration: none;
}
