.landingPage {
  min-height: 100%;
  background: url("../heroImage.jpeg") center no-repeat;
  background-size: cover;
  font-weight: 500;
}

.container {
  padding: 3vh 10%;
}

.hero {
  padding: 20% 0;
  margin: 0;
}

.hero__text {
  background: linear-gradient(
    rgb(242, 253, 255, 0.85),
    rgb(242, 253, 255, 0.85)
  );
  text-shadow: -1px -1px 0 rgb(242, 253, 255, 0.6),
    1px -1px 0 rgb(242, 253, 255, 0.6), -1px 1px 0 rgb(242, 253, 255, 0.6),
    1px 1px 0 rgb(242, 253, 255, 0.6);
  padding: 3vh 10% 6vh;
}

.bottom {
  background: linear-gradient(rgb(16, 25, 53, 0.85), rgb(16, 25, 53, 0.85));
  color: #f2fdff;
  text-shadow: -1px -1px 0 rgb(16, 25, 53, 0.9), 1px -1px 0 rgb(16, 25, 53, 0.9),
    -1px 1px 0 rgb(16, 25, 53, 0.9), 1px 1px 0 rgb(16, 25, 53, 0.9);
}

.bottom__text {
  max-width: 600px;
  margin: auto;
}

.bold {
  font-weight: 800;
}

.landingPageSearch {
  display: flex;
  justify-content: center;
  margin: 0;
}

.landingIcon {
  margin: 3vh 0;
}

.copyright {
  font-size: 12px;
  padding: 20px 0 0;
}

.copyright p {
  padding: 2px;
  margin: 0;
  display: inline;
}

@media (min-width: 750px) {
  .hero {
    padding: 15% 0;
  }
}

@media (min-width: 830px) {
  .hero {
    padding: 125px 0;
  }
}
